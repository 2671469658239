import ExampleSketch from './ExampleSketch';

export default {
  id: 'example-sketch',
  sketch: ExampleSketch,
  sketchArgs: {
    width: 800,
    height: 600,
  },
  isResponsive: false,
  backgroundColor: '#333333',
  showHeader: true,
  title: 'Example Sketch',
  headerColor: '#FFFFFF',
  minDimensions: {
    width: 800,
    height: 600,
  },
};
